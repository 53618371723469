import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type { VideoBoxProps, IVideoBoxStateRefValues } from '../VideoBox.types';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';

export default withCompController<
  VideoBoxProps,
  VideoBoxProps,
  VideoBoxProps,
  IVideoBoxStateRefValues
>(({ stateValues, mapperProps }) => {
  const { reducedMotion, experiments = {} } = stateValues;

  const isClassNameToRootEnabled = isExperimentOpen(
    experiments,
    'specs.thunderbolt.isClassNameToRootEnabled',
  );
  return { ...mapperProps, reducedMotion, isClassNameToRootEnabled };
});
